import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import footer_logo from "../../assets/images/footer-logo.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Footer = () => {
  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="footer">
      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Item className="remove-item-css">
                <div className="footer-text">
                  <p>
                    Conscisys Corporation provides IT systems design,
                    programming, project management and web development services
                    to a variety of business and government organizations
                  </p>

                  <div className="footer-logo">
                    <p className="powered-by">Powered by</p>
                    <img src={footer_logo} alt="Conscisys" width="100" />
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item md={4} sm={6}>
              <Item className="remove-item-css">
                <div className="footermenu">
                  <p className="mb-4 h4" role="heading" aria-level={3}>
                    Know more About Us
                  </p>
                  <ul className="mt-3">
                    <li className="mb-2">
                      <span
                        role="button"
                        className="cursor-pointer"
                        tabIndex={0}
                        onClick={() => scrollToSection("aboutus")}
                      >
                        About Us
                      </span>
                    </li>
                    <li className="mb-2">
                      <span
                        role="button"
                        className="cursor-pointer"
                        tabIndex={0}
                        onClick={() => scrollToSection("features")}
                      >
                        Features
                      </span>
                    </li>
                  </ul>
                </div>
              </Item>
            </Grid>
            <Grid item md={4} sm={6}>
              <Item className="remove-item-css">
                <div className="contact-dtl" style={{ textAlign: "left" }}>
                  <p className="mb-4 h4" role="heading" aria-level={3}>
                    Connect with us <br /> <br />
                    <Button
                      href="https://support.conscisys.com/support/"
                      target="_blank"
                      className="btn primary-button"
                      variant="contained"
                      style={{ fontSize: "0.80rem" }}
                      aria-label="Conscisys support"
                    >
                      Create Support Ticket
                    </Button>
                  </p>
                </div>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
