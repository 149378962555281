import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Marquee from "react-fast-marquee";
import { styled } from "@mui/material/styles";

import Header from "./Header";
import Footer from "./Footer";
import Faqs from "./Faqs";
import useProfile from "../../apis/auth/useProfile";
import PDF_icon from "../../assets/images/PDF-Conversion.svg";
import Stamped_icon from "../../assets/images/Stamped-Documents.svg";
import Summary_icon from "../../assets/images/Summary-Views.svg";
import Electronic_icon from "../../assets/images/Electronic-Service.svg";
import Reporting_icon from "../../assets/images/Detailed-Reporting.svg";
import our_system_img_1 from "../../assets/images/systems-img-1.png";
import our_system_img_2 from "../../assets/images/systems-img-2.png";
import our_system_img_3 from "../../assets/images/systems-img-3.png";
import our_system_img_4 from "../../assets/images/systems-img-4.png";
import useHomepageNotifcation from "../../apis/homepageNotification";
import easeofuse_icon from "../../assets/images/ease-of-use.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const LandingPage = () => {
  const navigate = useNavigate();
  const profile = useProfile();
  const homepageNotification = useHomepageNotifcation();

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (profile.isSuccess) {
      navigate("/dashboard");
    }
  }, [profile.isSuccess, navigate]);

  return (
    <div className="main">
      <Header notification={homepageNotification.data?.homePageNotification} />
      <div>
        <Box component="main">
          <CssBaseline />
          <div className="banner">
            <Container fixed>
              <div className="banner-text">
                <h1>
                  Save Your <br /> Time & <br />
                  Money
                </h1>
                <div className="banner-text-right">
                  <p>
                    <span>
                      Now Filing becomes easy for <strong>New Cases and</strong>
                    </span>
                    <br />
                    <span>
                      <strong>Additional Documents</strong> within any
                    </span>
                    <br />{" "}
                    <span>participating Illinois Court Jurisdiction</span>
                  </p>
                  {/* <Button className="btn btn-primary faq-btn mt-2">FAQ’S</Button> */}
                  <Button
                    className="btn faq-btn"
                    variant="contained"
                    onClick={() => scrollToSection("faqs")}
                  >
                    FAQ'S
                  </Button>
                </div>
              </div>
            </Container>
          </div>
          <div className="banner-note text-center ps-3 pe-3">
            <p className="fee-note">
              <a
                target="_blank"
                href="/#/filingfeesdetail"
                className="cursor-pointer text-underline"
                aria-label="filing Fee Details"
              >
                Click here
              </a>{" "}
              to know more about the filing fee changes.
            </p>
            <Marquee className="header-marquee">
              <p>{homepageNotification.data?.homePageTicker}</p>
            </Marquee>
          </div>
        </Box>
        <Box className="content-main">
          <section className="welcome-Portal text-center" id="aboutus">
            <Container fixed>
              <div className="welcome-Portal-dtl">
                <h2 className="red-pastel">Welcome to the I2File Portal</h2>
                <span className="sub-title-text">
                  In our Product, you will get all the Standard Features for
                  Filing
                </span>
                <p>
                  Our product was designed with you in mind. We know you want to
                  get your filing submitted as quick and as easy as possible. We
                  have designed our product to have the fewest pages possible
                  with help text available all along the way. We give you the
                  tools necessary to ensure your firm will never miss an
                  eService
                </p>
              </div>
            </Container>
          </section>
          <section className="our-features text-center" id="features">
            <Container fixed>
              <h2 className="red-pastel">Our Features</h2>
              <span className=" pb-78 d-block sub-title-text">
                I2File gives you all the standard features you would expect from
                your e-filing service provider
              </span>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid className="pt-0 mb-5" item xs={6} sm={4}>
                    <Item className="remove-item-css text-center">
                      <div className="features-img mb-4">
                        <img src={easeofuse_icon} alt="Ease of use" />
                      </div>
                      <p className="mb-4 h4" role="heading" aria-level={1}>
                        Ease Of Use
                      </p>
                      <p>
                        Now Filing becomes easy for New Cases and Additional
                        Documents within any participating Illinois Court
                        Jurisdiction to Save Time, Money...
                      </p>
                    </Item>
                  </Grid>
                  <Grid className="pt-0 mb-5" item xs={6} sm={4}>
                    <Item className="remove-item-css text-center">
                      <div className="features-img mb-4">
                        <img src={PDF_icon} alt="PDF Conversion" />
                      </div>
                      <p className="mb-4 h4" role="heading" aria-level={1}>
                        PDF Conversion
                      </p>
                      <p>
                        No need to convert your word processing documents to PDF
                        before filing - simply upload them for conversion to
                        text searchable PDFs
                      </p>
                    </Item>
                  </Grid>
                  <Grid className="pt-0 mb-5" item xs={6} sm={4}>
                    <Item className="remove-item-css text-center">
                      <div className="features-img mb-4">
                        <img src={Stamped_icon} alt="File Stamped Documents" />
                      </div>
                      <p className="mb-4 h4" role="heading" aria-level={1}>
                        File Stamped Documents
                      </p>
                      <p>
                        Once accepted, your file stamped documents will be
                        attached to your accepted notification email for easy
                        access
                      </p>
                    </Item>
                  </Grid>
                  <Grid className="pt-0 mb-xs-5" item xs={6} sm={4}>
                    <Item className="remove-item-css text-center">
                      <div className="features-img mb-4">
                        <img src={Summary_icon} alt="Case Summary Views" />
                      </div>
                      <p className="mb-4 h4" role="heading" aria-level={1}>
                        Case Summary Views
                      </p>
                      <p>
                        View basic case and party information, judge assignment,
                        and a register of e-filing activity on your case.
                      </p>
                    </Item>
                  </Grid>
                  <Grid className="pt-0 mb-xs-5" item xs={6} sm={4}>
                    <Item className="remove-item-css text-center">
                      <div className="features-img mb-4">
                        <img src={Electronic_icon} alt="Electronic Service" />
                      </div>
                      <p className="mb-4 h4" role="heading" aria-level={1}>
                        Electronic Service
                      </p>
                      <p>
                        Complimentary electronic service and tracking along with
                        your filing. Just identify your service contacts and
                        we'll take care of the rest.
                      </p>
                    </Item>
                  </Grid>
                  <Grid className="pt-0" item xs={6} sm={4}>
                    <Item className="remove-item-css text-center">
                      <div className="features-img  mb-4">
                        <img src={Reporting_icon} alt="Detailed Reporting" />
                      </div>
                      <p
                        className="mb-4 h4"
                        aria-label="I2FileLinking"
                        role="heading"
                        aria-level={1}
                      >
                        Detailed Reporting
                      </p>
                      <p>
                        Run detailed filing activity reports at any time to view
                        all your filings and associated fees and client matter
                        numbers.
                      </p>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </section>
          <section className="our-systems">
            <Container fixed>
              <h2 className="white-text pb-78">
                Our Systems- <span>More Legal Apps</span>
              </h2>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={9} lg={10}>
                    <Item className="remove-item-css">
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} justifyContent="center">
                          <Grid item sm={3}>
                            <Item className="remove-item-css">
                              <div className="img-caption text-center">
                                <img
                                  src={our_system_img_1}
                                  className="img-fluid"
                                  alt="Our Systems"
                                />
                                <div className="img-caption-bg">
                                  <h3>
                                    <span>Search Warrant System</span>
                                  </h3>
                                </div>
                              </div>
                            </Item>
                          </Grid>
                          <Grid item sm={3}>
                            <Item className="remove-item-css">
                              <div className="img-caption text-center">
                                <img
                                  src={our_system_img_2}
                                  className="img-fluid"
                                  alt="Our Systems"
                                />
                                <div className="img-caption-bg">
                                  <h3>
                                    <span>IUCS System </span>
                                  </h3>
                                </div>
                              </div>
                            </Item>
                          </Grid>
                          <Grid item sm={3}>
                            <Item className="remove-item-css">
                              <div className="img-caption text-center">
                                <img
                                  src={our_system_img_3}
                                  className="img-fluid"
                                  alt="Our Systems"
                                />
                                <div className="img-caption-bg">
                                  <h3>
                                    <span>
                                      Documents Validation <br /> Services
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            </Item>
                          </Grid>
                          <Grid item sm={3}>
                            <Item className="remove-item-css">
                              <div className="img-caption text-center">
                                <img
                                  src={our_system_img_4}
                                  className="img-fluid"
                                  alt="Our Systems"
                                />
                                <div className="img-caption-bg">
                                  <h3>
                                    <span>e-Sign Documents</span>
                                  </h3>
                                </div>
                              </div>
                            </Item>
                          </Grid>
                        </Grid>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid
                    item
                    className="more-system-col"
                    md={3}
                    lg={2}
                    sx={{ margin: { xs: "0 auto" } }}
                  >
                    <Item className="more-system-main">
                      <p className="more-systems">
                        <a
                          className="cursor-pointer text-underline"
                          target="_blank"
                          rel="noreferrer"
                          role="button"
                          href={process.env.REACT_APP_LEGAL_APPS_URL}
                          style={{ marginLeft: "6px" }}
                        >
                          Explore More <br /> Systems
                        </a>
                      </p>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </section>
          <section className="faq-sec" id="faqs">
            <Container fixed>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item sm={4} xs={12}>
                    <Item className="remove-item-css text-right ask-question">
                      <h2 className="red-pastel">
                        Frequently <br />
                        Asked Questions
                      </h2>
                      <span className="mt-2 d-block sub-title-text">
                        We are here to reply all your queries. Feel free to ask
                        us.
                      </span>
                      <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        className="btn primary-button mb-2 custom-font-size"
                        
                        variant="contained"
                        onClick={() => navigate("/support")}
                      >
                        Help & support
                      </Button>
                      <Button
                        href="assets/pdf/I2File-User_Manual_v1.0.pdf"
                        className="btn button-outline-secondary ms-2 mb-2 custom-font-size"
                        target="_blank"
                        
                        rel="noopener noreferrer"
                        aria-label="I2File User Manual"
                      >
                        User Manual
                      </Button>
                      </div>
                    </Item>
                  </Grid>
                  <Grid className="faq-dtl-main" item sm={8} xs={12}>
                    <Item className="remove-item-css">
                      <div className="faq-dtl text-left">
                        <Faqs />
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </section>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
